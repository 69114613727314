import React, {useState} from "react";
import {css} from "@emotion/react"
import { Helmet } from "react-helmet";

const PEOPLE = [
  {
    name: "Jim The Peasant",
    netWorth: "-$850",
    class: "poor",
    fileName: "peasant.jpeg"
  },
  {
    name: "Sarah Serf",
    netWorth: "-$28,000",
    class: "poor",
    fileName: "fres.jpeg"
  },
  {
    name: "Leonardo Da Vinci",
    netWorth: "$5000",
    class: "middle",
    fileName: "leo.jpeg"
  },

  {
    name: "John The Peasant",
    netWorth: "$0",
    class: "poor",
    fileName: "peasant.jpeg"
  },
  {
    name: "Isaac Newton",
    netWorth: "$10,000",
    class: "middle",
    fileName: "newton.jpeg"
  },
  {
    name: "Abraham Lincoln",
    netWorth: "$50",
    class: "poor",
    fileName: "abe.jpeg"
  },
  {
    name: "Alexander Hamilton",
    netWorth: "$10",
    class: "poor",
    fileName: "ham.jpeg"
  },
  {
    name: "Karl Marx",
    netWorth: "$2000",
    class: "middle",
    fileName: "karl.jpeg"
  },
  {
    name: "Richard The Peasant",
    netWorth: "$0",
    class: "poor",
    fileName: "peasant.jpeg"
  },
  {
    name: "Mansa Musa",
    netWorth: "$400 Billion",
    class: "rich",
    fileName: "mansa.jpeg"
  },
]

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}


// markup
const NetWorthSimulator = () => {
  const [personIndex, setPersonIndex] = useState(0)
  const person = PEOPLE[personIndex]

  const getMessage = () => {
    if (person.class === "poor"){
      return (<h2 css={css`color: red;`}>You were born poor, <br></br> better luck next time.</h2>)
    } else if (person.class === "middle"){
      return (<h2 css={css`color: orange;`}>You were born middle-class. <br></br>Not bad, but not great.</h2>)
    } else {
      return (<h2 css={css`color: green;`}>You have won the birth lottery!! CONGRATS!!</h2>)
    }
  }

  const onButtonClick = () => {
    const rand = getRandomInt(20) + 1
    if (rand < 20){
      // poor
      let val = getRandomInt(9)
      while (val === personIndex){
        val = getRandomInt(9)
      }
      setPersonIndex(val)
    } else {
      setPersonIndex(9)
    }
  }


  return (
    <main css={css`
      display: flex;
      flex-direction: column;
      align-items: center;
    `}>
      <Helmet>
        <title>Net Worth Simulator</title>
      </Helmet>
      <h1>Net Worth Simulator</h1>
      <div css={css`
        width: 300px;
        text-align: center
      `}>
      {getMessage()}
      </div>
      <img css={css`
        width: 300px;
        height: 300px;
        border-radius: 20px;
      `} src={`/${person.fileName}`} alt={person.name}></img>
      <div css={css`
        font-size: 24px;
        margin-top: 12px;
      `}><span css={css`
        font-size: 20px;
        color: #333
      `}>Name:</span> {person.name}</div>
      <div css={css`font-size: 24px;`}><span  css={css`
      font-size: 20px;

          color: #333
        `}>Net Worth At Birth: </span>{person.netWorth}</div>
      <button css={css`
          margin-top: 20px;
          font-size: 18px;
          padding: 4px;
      `} onClick={onButtonClick}>Respawn</button>
    </main>
  )
}

export default NetWorthSimulator
